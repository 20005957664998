import {ClipLoader, BarLoader} from 'react-spinners';

export function showLoaderScreen(show){
    if(show){
      return (<div className="menuItemDetail-back App" ><div className="loaderOverlay">{<ClipLoader sizeUnit={"px"} size={100} color={'#2E86DE;'} loading={true} />}</div></div>)
    }else{
      return null
    }
  }

  export function showBarLoaderScreen(show){
    if(show){
      return (<div className="menuItemDetail-back App" ><div className="loaderOverlay">{<BarLoader css="z-index: 20000000;" sizeUnit={"px"} height={3} width={300} color={'#348ED8;'} loading={true} />}</div></div>)
    }else{
      return null
    }
  }

  export function embeddedLoader(show){
    if(show){
      return (<ClipLoader sizeUnit={"px"} size={100} color={'#2E86DE;'} loading={true} />)
    }else{
      return null
    }
  }