import firebase from 'firebase/app';
import {getEpochFor,addEpoch} from './DateFunctions.js'
//var firebase = require('firebase/app');
// Required for side-effects
require("firebase/firestore");
require("firebase/storage");
require("firebase/analytics");
require("firebase/functions");
require("firebase/auth");


// Initialize Firebase
var config = {
    apiKey: "AIzaSyCPn_T9su0mShkfh-AoOQSSZW96YPHl3tY",
    authDomain: "contact-tracing-c2bc6.firebaseapp.com",
    projectId: "contact-tracing-c2bc6",
    storageBucket: "contact-tracing-c2bc6.appspot.com",
    messagingSenderId: "498756133189",
    appId: "1:498756133189:web:dda170acc07f1ac98e5e2c",
    measurementId: "G-69GRF8GHRH"
};
firebase.initializeApp(config);
firebase.analytics();

const topdb = firebase.firestore();
const functions = firebase.functions();
const storage = firebase.storage()

export const kAppVersion = '0.1.7'
export let kClientID = null

function db(){
    if(!!kClientID){
        return topdb.collection('clients').doc(kClientID)
    }else{
        return topdb;
    }
}


export function listenForLogs(listener,isOpen,callback,limit){
    console.log('listenForOpenLog')
    const uid = getCurrentUser().uid
    let query = db().collection("logs").where('uid', '==', uid).where('open', '==', isOpen);
    if(isOpen){
        query = query.orderBy('inYear', 'desc').orderBy('inMonth', 'desc').orderBy('inDay', 'desc').orderBy('inHour', 'desc').orderBy('inMin', 'desc')
    }else{
        query = query.orderBy('outYear', 'desc').orderBy('outMonth', 'desc').orderBy('outDay', 'desc').orderBy('outHour', 'desc').orderBy('outMin', 'desc')
    }
    query = query.limit(limit)
    

    if(listener){
        console.log('removeListener')
        listener()
    }
    listener = query.onSnapshot(querySnapshot => {
        console.log(`Received query snapshot of logs ${querySnapshot.size}`);
        if(!querySnapshot.empty){
            callback(querySnapshot.docs)
        }else{
            //No Results
            console.log("Query returned no results");
            callback([])
        }
    }, err => {
        console.log(`Error listening to logs: ${err}`);
        callback([])
    });
    return listener;
}

export function adminListenToLogs(listener,callback,limit,filters){
    console.log('listenForOpenLog')
    let query = db().collection("logs");//.where('uid', '==', uid).where('open', '==', isOpen);
    if(!!filters && Object.keys(filters).length > 0){
        if(!!filters.location && filters.location.length>0){
            query = query.where('locationName', 'in', filters.location)
        }
    }
    query = query.orderBy('inYear', 'desc').orderBy('inMonth', 'desc').orderBy('inDay', 'desc').orderBy('inHour', 'desc').orderBy('inMin', 'desc')
    //query = query.orderBy('outYear', 'desc').orderBy('outMonth', 'desc').orderBy('outDay', 'desc').orderBy('outHour', 'desc').orderBy('outMin', 'desc')
    query = query.limit(limit)
    

    if(listener){
        console.log('removeListener')
        listener()
    }
    listener = query.onSnapshot(querySnapshot => {
        console.log(`Received query snapshot of logs ${querySnapshot.size}`);
        if(!querySnapshot.empty){
            callback(querySnapshot.docs)
        }else{
            //No Results
            console.log("Query returned no results");
            callback([])
        }
    }, err => {
        console.log(`Error listening to logs: ${err}`);
        callback([])
    });
    return listener;
}

export function adminSnapLogs(callback,limit,filters,logHistory,setLogHistoryState){
    console.log('adminSnapLogs')
    let query = db().collection("logs");//.where('uid', '==', uid).where('open', '==', isOpen);
    if(!!filters && Object.keys(filters).length > 0){
        if(!!filters.location && filters.location.length>0){
            query = query.where('locationName', 'in', filters.location)
        }
    }
    query = query.orderBy('inYear', 'desc').orderBy('inMonth', 'desc').orderBy('inDay', 'desc').orderBy('inHour', 'desc').orderBy('inMin', 'desc')
    //query = query.orderBy('outYear', 'desc').orderBy('outMonth', 'desc').orderBy('outDay', 'desc').orderBy('outHour', 'desc').orderBy('outMin', 'desc')
    query = query.limit(limit)
    if(!!logHistory && logHistory.length > 0){
        query = query.startAfter(logHistory[logHistory.length-1])
    }
    
    query.get()
    .then(querySnapshot => {
        console.log(`Received query snapshot of logs ${querySnapshot.size}`);
        let sa = []
        let logHistoryState = 'loaded'
        if(!querySnapshot.empty){
            sa = querySnapshot.docs
            if(!!logHistory && logHistory.length > 0){
                sa = logHistory.concat(sa)
            }
        }else{
            //No Results
            console.log("Query returned no results");
            sa = []
            if(!!logHistory && logHistory.length > 0){
                sa = logHistory
            } 
            logHistoryState = 'end'  
        }
        callback(sa)
        setLogHistoryState(logHistoryState)
    })
    .catch(err => {
        console.log(`Error listening to logs: ${err}`);
        callback([])
    });
}

export function createNewClient(email, pass, fullName, clientName, tier, currentURL, PRICE_ID, setErrorMsg,setLoading){
    //createNewClient
    console.log('createNewClient')
    const createClient = functions.httpsCallable('createNewClient');
    return createClient({email:email, password:pass,fullName:fullName, clientName:clientName, tier:tier})
    .then(result =>{
        const clientID = result.data.clientID
        return createCheckout(clientID,PRICE_ID,currentURL, email)
    })
    .catch(error =>{
        console.error(error)
        setErrorMsg('Sorry, something went wrong. Please try again.')
        setLoading(false)
    })
}

export function createCheckout(clientID,PRICE_ID,currentURL, email){
    const createCheckoutCallable = functions.httpsCallable('createCheckout');
    const checkoutObject = {
        mode: 'subscription',
        payment_method_types: ['card'],
        line_items: [
          {
            price: PRICE_ID,
          },
        ],
        customer_email:email,
        success_url: currentURL+'/success/{CHECKOUT_SESSION_ID}',
        cancel_url: currentURL,
        client_reference_id:clientID
      }
    return createCheckoutCallable({priceId:PRICE_ID,checkoutObject:checkoutObject})
}

let exporting = false
export function exportToCSV(callback){
    if(!exporting){
        exporting = true
        const exportCSV = functions.httpsCallable('exportCSV');
        exportCSV({ clientID:kClientID})
        .then((result) => {
            // Read result of the Cloud Function.
            const fileName = result.data.fileName
            storage.ref().child(fileName).getDownloadURL()
            .then((url) => {
                // `url` is the download URL for 'images/stars.jpg'
                console.log(url)
                exporting = false
                callback(url)
            })
            .catch((error) => {
                console.error(error)
                exporting = false
                callback(false)
            });
        })
        .catch(error => {
            console.error(error)
            exporting = false
            callback(false)
        })
    }
}

export function getLogsForUser(open,callback){
    console.log('getLogsForUser')
    const uid = getCurrentUser().uid
    const query = db().collection("logs").where('uid', '==', uid).where('open', '==', open);

    query.get()
    .then(qSnap => {
        if(!qSnap.empty){
            callback(qSnap.docs)
        }else{
            //No Results
            console.log("Query returned no results");
            callback([])
        }
    })
    .catch(error => {
        console.error("Error loading lolcations:" + error);
        callback([])
    })
}

export function logCheckIn(date,time,locationObject,callback){
    console.log('logCheckIn')
    const currentUser = getCurrentUser()
    const uid = currentUser.uid
    const userEmail = currentUser.email
    const fullName = currentUser.displayName
    const epoch = getEpochFor(date,time)
    const logData = {
        open:true,
        dateInCreated:firebase.firestore.FieldValue.serverTimestamp(),
        uid:uid,
        email:userEmail,
        fullName:fullName,
        locationID:locationObject.id,
        locationName:locationObject.data().name,
        inYear:date.year,
        inMonth:date.month,
        inDay:date.day,
        inHour:time.hour,
        inMin:time.minute,
        inEpoch:epoch
    }
    db().collection("logs").add(logData)
    .then(() => {
        //success
        callback('success')
    })
    .catch(error => {
        //fail
        console.error("Error creating Log:" + error);
        callback('fail')
    })
}

export function logCheckOut(date,time,logID,callback){
    console.log('logCheckOut')
    const epoch = getEpochFor(date,time)
    const logData = {
        open:false,
        dateOutCreated:firebase.firestore.FieldValue.serverTimestamp(),
        outYear:date.year,
        outMonth:date.month,
        outDay:date.day,
        outHour:time.hour,
        outMin:time.minute,
        outEpoch:epoch
    }
    db().collection("logs").doc(logID).update(logData)
    .then(() => {
        //success
        callback('success')
    })
    .catch(error => {
        //fail
        console.error("Error creating Log:" + error);
        callback('fail')
    })
}

export function deleteLog(log,callback){
    console.log('deleteLog')
    db().collection("deletedLogs").doc(log.id).set(log.data())
    .then(() => {
        db().collection('logs').doc(log.id).delete()
        .then(() =>{
            //success
            callback('success')
        })
    })
    .catch(error => {
        //fail
        console.error("Error creating Log:" + error);
        callback('fail')
    })
}

function getLocations(){
    console.log('getLocations')
    let locationSearchRef =  db().collection("locations")
    locationSearchRef = locationSearchRef.where('active', '==', true)
    locationSearchRef = locationSearchRef.orderBy('name')
    return locationSearchRef.get()
    .then(qSnap => {
        if(!qSnap.empty){
            return qSnap.docs
        }else{
            //No Results
            console.log("Query returned no results");
            return false
        }
    })
    .catch(error => {
        console.error("Error loading lolcations:" + error);
        return false
    })
}


export function saveLocation(id,name,active,callback){
    console.log('saveLocations')
    const collectionRef = db().collection("locations")
    const data = {name:name,active:active}
    if(!!id && id.length > 0){
        data['dateUpdated'] = firebase.firestore.FieldValue.serverTimestamp()
        collectionRef.doc(id).update(data).then(() => {
            console.log("Updated location: "+id);
            callback('success')
        })
        .catch(error => {
            console.error("Error updating lolcation:" +error)
            callback('failed')
        })  
    }else{
        data['dateCreated'] = firebase.firestore.FieldValue.serverTimestamp()
        collectionRef.add(data).then((res) => {
            console.log("Added location: "+res.id);
            callback('success')
        })
        .catch(error => {
            console.error("Error saving location:" +error)
            callback('failed')
        })  
    }
}

export function loadClient(callback){
    console.log('loadClient')
    db().get()
    .then(clientSnapshot =>{
        if(clientSnapshot.exists){
            const client = {id:clientSnapshot.id,clientDoc:clientSnapshot.data()}
            getLocations().then(result =>{
                if(!!result && result.length >0){
                    client['locations'] = result
                }else{
                    //No Results
                    console.log("No locations");
                }
                callback(client)
            })
        }else{
            //No Results
            console.log("Client does not exist");
            callback([])
        }
    })
    .catch(error => {
        console.error("Error loading client:" + error);
        callback([])
    })
}

export function getUsers(callback){
    console.log('getUsers')
    let locationSearchRef =  db().collection("users")
    // locationSearchRef = locationSearchRef.where('active', '==', active)
    locationSearchRef = locationSearchRef.orderBy('email')
    locationSearchRef.get()
    .then(qSnap => {
        if(!qSnap.empty){
            callback(qSnap.docs)
        }else{
            //No Results
            console.log("Query returned no results");
            callback([])
        }
    })
    .catch(error => {
        console.error("Error loading Users:" + error);
        callback([])
    })
}

export function getAdmins(callback){
    console.log('getAdmins')
    let searchRef =  db().collection("adminUsers")
    searchRef = searchRef.where('isAdmin', '==', true)
    searchRef.get()
    .then(qSnap => {
        if(!qSnap.empty){
            callback(qSnap.docs)
        }else{
            //No Results
            console.log("Query returned no results");
            callback([])
        }
    })
    .catch(error => {
        console.error("Error loading Admins:" + error);
        callback([])
    })
}

export function listenForAdmins(listener,callback){
    console.log('listenForAdmins')
    let query =  db().collection("adminUsers")
    query = query.where('isAdmin', '==', true)
    

    if(listener){
        console.log('removeListener')
        listener()
    }
    listener = query.onSnapshot(querySnapshot => {
        console.log(`Received query snapshot of logs ${querySnapshot.size}`);
        if(!querySnapshot.empty){
            callback(querySnapshot.docs)
        }else{
            //No Results
            console.log("Query returned no results");
            callback([])
        }
    }, err => {
        console.log(`Error listening to logs: ${err}`);
        callback([])
    });
    return listener;
}

export function setAdmin(isAdmin, uid, callback){
    console.log('getAdmins')
    db().collection("adminUsers").doc(uid).set({isAdmin:isAdmin,uid:uid})
    .then(qSnap => {
        console.log("Admin Saved");
            callback('success')
    })
    .catch(error => {
        console.error("Error saving admins:" + error);
        callback('failure')
    })
}

export function listenExposures(listener,callback){
    console.log('listenForExposures')
    let query =  db().collection("exposure").orderBy('createdTimestamp','desc')

    if(listener){
        console.log('removeListener')
        listener()
    }
    listener = query.onSnapshot(querySnapshot => {
        console.log(`Received query snapshot of exposures ${querySnapshot.size}`);
        if(!querySnapshot.empty){
            callback(querySnapshot.docs)
        }else{
            //No Results
            console.log("Query returned no results");
            callback([])
        }
    }, err => {
        console.log(`Error listening to logs: ${err}`);
        callback([])
    });
    return listener;
}

export function listenToExposure(listener,exposureID,callback){
    console.log('listenToExposure')

    if(listener){
        console.log('removeListener')
        listener()
    }
    listener = db().collection("exposure").doc(exposureID).onSnapshot(docSnapshot => {
        console.log(`Received query snapshot of exposure`);
        if(docSnapshot.exists){
            callback(docSnapshot)
        }else{
            //No Results
            console.log("Query returned no results");
            callback(null)
        }
    }, err => {
        console.log(`Error listening to exposure: ${err}`);
        callback(null)
    });
    return listener;
}

export function newExposure(uid, fullName, email, startDate, endDate, callback){
    console.log('newExposure')
    const data = {
        createdTimestamp:firebase.firestore.FieldValue.serverTimestamp(),
        clientID:kClientID,
        uid:uid,
        fullName:fullName,
        email:email,
        startDate:addEpoch(startDate),
        endDate:addEpoch(endDate),
        running:true
    }
    console.log(data)
    db().collection('exposure').add(data)
    .then(() =>{
        callback('success')
    })
    .catch(error => {
        console.error("Error saving exposure:" + error);
        callback('failure')
    })
}


//Auth
let authTokenClaims = null

export function authUID(){
    const currentUser = getCurrentUser()
    if(currentUser){
        return currentUser.uid
    }else{
        return false
    }
}

export function getCurrentUser(){
    if(firebase.auth().currentUser){
        return firebase.auth().currentUser
    }else{
        return false
    }
}

export function signOut() {
    firebase.auth().signOut();
}

export function signIn(email,password,callback) {
    if(email.length <= 0){
        callback('Please enter a valid email address.')
    }else{
        authPersist()
        firebase.auth().signInWithEmailAndPassword(email, password)
        .then(firebaseUser => {
            //successful log in
            console.log('sign in success')
            callback('')
            return true
        })
        .catch(error => {
            console.log(error)
            callback('The email or password you entered doesnt match our records. Please try again or click Forgot Password.')
        });
    }
}

export function setPassword(password, loadingState, callback){
    loadingState(true)
    const user = firebase.auth().currentUser
    user.updatePassword(password)
    .then(() => {
        db().collection('members').doc(user.uid).update({
            isNew:false
        })
        .then(() => {
            loadingState(false)
            callback('success')
        })
        .catch(error => {
            loadingState(false)
            callback('failed')
        })
    })
    .catch(error => {
        loadingState(false)
        callback('failed')
    })
}

export function validateClientID(clientID,callback){
    console.log('validateClientID')
    topdb.collection('clients').doc(clientID).get()
    .then(docSnap =>{
        if(docSnap.exists){
            callback('valid')
        }else{
            callback('invalid')
        }
    })
    .catch(error =>{
        console.error(error)
        callback('invalid')
    })
}



export function createAccount(email, password, confirmPassword, fullName, clientID, callback){
    if(password !== confirmPassword){
        callback('The passwords you entered do not match!')
    }else if(email && email.length <= 0){
        callback('Please enter a valid email address.')
    }else{
        authPersist()
        console.log('signUpAttempted')
        firebase.auth().createUserWithEmailAndPassword(email, password)
        .then(firebaseUser=>{
            //successful signup
            const uid = firebaseUser.user.uid
            topdb.collection('clients').doc(clientID).collection("users").doc(uid).set({
                uid:uid,
                email:email,
                fullName:fullName,
                dateCreated:firebase.firestore.FieldValue.serverTimestamp(),
                dateUpdated:firebase.firestore.FieldValue.serverTimestamp(),
                active:true
            })
            .then(() => {
                firebaseUser.user.updateProfile({
                    displayName: fullName
                })
                .then(() =>{
                    console.log('sign up success')
                    callback('')
                })
                .catch(error => {
                    console.error(error)
                })
            })
            .catch(error => {
                console.error(error)
            })
        })
        .catch(error => {
            if(error){
            console.log(error.message + error.code)
            callback('Something went wrong creating your account... Please try again')
            }
        });
    }
}

export function forgotPassword(email,callback){
    firebase.auth().sendPasswordResetEmail(email)
    .then(() => {
        callback('A password reset email has been sent to you at '+email)
        // Email sent.
    })
    .catch(error => {
        // An error happened.
        console.error(error)
        callback('The email adress you entered is not valid')
    });
}

export function setUserDisabled(uid, isDisabled,callback){
    const setUser = firebase.functions().httpsCallable('setUserDisabled');
    setUser({ uid:uid,clientID:kClientID,disabled:isDisabled})
    .then((result) => {
        // Read result of the Cloud Function.
        const success = result.data.success
        if(success){
            callback('success')
        }else{
            callback('failure')
        }
    });
}

export function onAuthStateChange(callback, authState, unsubscribe){
    if(authState === 'unloaded'){
        if(unsubscribe){
            unsubscribe()
        }
        return firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // User is signed in.
                // load favorites
                
                console.log('state change Signed In')
                user.getIdTokenResult()
                .then((idTokenResult) => {
                    // Confirm the user is an Admin.
                    authTokenClaims = idTokenResult.claims
                    kClientID = idTokenResult.claims.clientID
                    if (!!idTokenResult.claims.isAdmin) {
                        // Show admin UI.
                        console.log('is Admin')
                        callback('admin')
                    } else {
                        // Show regular user UI.
                        console.log('is not Admin')
                        callback('auth')
                    }
                })
                .catch((error) => {
                    console.log(error);
                    callback('auth')
                });
              }else{
                authTokenClaims = null
                callback('unauth')
                console.log('state change Signed Out')
              }
        })
    }

}

export function isAdmin(callback){
    if(!!firebase.auth().currentUser){
        firebase.auth().currentUser.getIdTokenResult()
        .then((idTokenResult) => {
            // Confirm the user is an Admin.
            if (!!idTokenResult.claims.isAdmin) {
            // Show admin UI.
            callback(true)
            } else {
            // Show regular user UI.
            callback(false)
            }
        })
        .catch((error) => {
            console.log(error);
            callback(false)
        });
    }else{
        callback(false)
    }
    

}

export function isAgency(callback){
    if(!!firebase.auth().currentUser){
        firebase.auth().currentUser.getIdTokenResult()
        .then((idTokenResult) => {
            // Confirm the user is an Admin.
            if (!!idTokenResult.claims.agency) {
            // Show admin UI.
            callback(idTokenResult.claims.agency)
            } else {
            // Show regular user UI.
            callback(false)
            }
        })
        .catch((error) => {
            console.log(error);
            callback(false)
        });
    }else{
        callback(false)
    }
    

}

function authPersist() {
    console.log('signInAttempted')
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(function() {
        //auth is persisted
    })
    .catch(error => {
        if(error){
        console.log(error.message + error.code)
        //alert(error.message)
        }
    });
}