import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link,useRouteMatch,useParams} from "react-router-dom";
import { signOut, loadClient, kClientID, listenForLogs, logCheckIn, logCheckOut,deleteLog,getLogsForUser, getCurrentUser } from './Firebase.js'
import {showLoaderScreen} from './LoaderScreen.js'
import TimeKeeper from 'react-timekeeper';
import {validateCheckoutDate, getFormattedDateFromEpoch, getFormattedSimpleTime, locationObject, getCurrentDateTimeData, getDateTimeFromLog, getLogDateSummary, getFormattedDate, changeDateByDays} from './DateFunctions.js'


export function TraceApp(props){
    const authState = props.authState
    let match = useRouteMatch();
    const displayName = getCurrentUser().displayName
    const [menu, setMenu] = useState(false);
    const [clientData, setClientData] = useState('unloaded');
    const [loading, setLoading] = useState(false);
    if(clientData === 'unloaded' && !loading && !!kClientID){
        setLoading(true)
        loadClient((data) => {setClientData(data); setLoading(false)})
    }
    const clientName = (clientData !== 'unloaded' ? clientData.clientDoc.clientName : null)
    return(
        <div>
            {showLoaderScreen(loading)}
            {!!kClientID ?
            <div>
                <h2>{clientName}</h2>
                <h3>Welcome{!!displayName && displayName.length > 0 ? ', '+displayName : null}!</h3>
                <Switch>
                    <Route path="/location/:locationID">
                        <Console isQuickLink={true} clientData={clientData} />
                    </Route>
                    <Route path="/history">
                        <History clientData={clientData} />
                    </Route>
                    <Route path={match.path}>
                        <Console isQuickLink={false} clientData={clientData} />
                    </Route>
                </Switch>
                <div className="spacer" />
                {menu ?
                    <div className="navMenu" onClick={()=> setMenu(!menu)}>
                        <button className="leftCornerMenu leftCornerMenuMain" onClick={()=> setMenu(!menu)}><i className="material-icons-outlined md-28 featureIcon">menu_open</i></button>
                        <nav className="navBtnBox">
                            <div className="navLightLine" />
                            <Switch>
                                <Route path="/history">
                                    <Link to="/"><button className="leftCornerMenu"><i className="material-icons-outlined md-28 featureIcon">home</i> Home</button></Link>
                                </Route>
                                <Route path={match.path}>
                                    <Link to="/history"><button className="leftCornerMenu"><i className="material-icons-outlined md-28 featureIcon">history</i> History</button></Link>
                                </Route>
                            </Switch>
                            <div className="navLightLine" />
                            {authState === 'admin' ? <Link to="/admin"><button className="leftCornerMenu"><i className="material-icons-outlined md-28 featureIcon">dashboard</i> Admin</button><br /></Link> :null}
                            <button className="leftCornerMenu" onClick={signOut}><i className="material-icons-outlined md-28 featureIcon">logout</i> Sign Out</button>
                        </nav>
                    </div>
                :
                    <button className="leftCornerMenu leftCornerMenuMain" onClick={()=> setMenu(!menu)}><i className="material-icons-outlined md-28 featureIcon">menu</i></button>
                }
                <div className="spacer" />
                <div className="spacer" />
            </div>
            :
                <div>
                    <h2>Your account is being set up. Please refresh this page in a few minutes, and sign in.</h2>
                    <button className="linkButton" onClick={() => window.location.reload()}>Refresh</button>
                </div>
            }
            
        </div>
    )
}

let openListener = null
function Console(props){
    const clientData = props.clientData
    const clientName = (clientData !== 'unloaded' ? clientData.clientDoc.clientName : null)
    const isQuickLink = props.isQuickLink
    let { locationID } = useParams();
    const [showCheckin, setShowCheckin] = useState(isQuickLink && !!locationID);
    const displayName = getCurrentUser().displayName

    const [logs, setLogs] = useState('unloaded')
    if(logs === 'unloaded'){
        setLogs('loading')
        openListener = listenForLogs(openListener,true, setLogs, 2)
    }

    return(
        <div>
            {clientData !== 'unloaded' && logs !== 'unloaded' && logs !== 'loading' ?
                <div>
                    <div className="spacer" />
                    {logs.length <= 0 ?
                        <button className="mainButtonContrast" onClick={() => setShowCheckin(!showCheckin)} disabled={false}>Check in</button>
                    :
                        <div>
                            {logs.map(log => <h2 key={log.id}>Currently in {log.data().locationName}</h2>)}
                            <button className="mainButtonContrast mainButtonContrastRed" onClick={() => setShowCheckin(!showCheckin)} disabled={false}>Check out</button>
                        </div>
                    }
                    {showCheckin ? <Checkin locationID={locationID} logs={logs} dismiss={() => setShowCheckin(false)} clientData={clientData} /> : null}
                    <div className="spacer" />
                    <i className="material-icons-round artIcon">linear_scale</i>
                </div>
            :
                showLoaderScreen(true)
            }
        </div>
    )
}

function Checkin(props){
    const dismiss = props.dismiss
    const locations = props.clientData.locations
    const locationID = props.locationID
    const isCheckOut = !!props.logs && props.logs.length > 0
    let log = isCheckOut ? props.logs[0] : null
    const currentDateTimeData = getCurrentDateTimeData()

    const [selectedLocation, setSelectedLocation] = useState(locationID || 'select')
    const [date, setDate] = useState(currentDateTimeData.date) //{year:2021, month:1, day:3}
    const [time, setTime] = useState(currentDateTimeData.time)//{hour: 14, minute: 55, formatted: "1:55 pm"}
    const [showTime, setShowTime] = useState(false)
    const [loading, setLoading] = useState(false)



    return(
        <div className="modal-back" >
            <div className="modal-content">
                {showLoaderScreen(loading)}
                <button onClick={dismiss} className="closeButton"> <i className="material-icons-round md-48">close</i></button>
                <div className="clearDiv" />
                <h2>{isCheckOut ? 'Check Out' : 'Check In'}</h2>
                <h3>
                    <button className="inlineArrowButton" onClick={() => setDate(changeDateByDays(date,-1))}><i className="material-icons-outlined md-22">{'arrow_back_ios'}</i></button>
                        {getFormattedDate(date.year,date.month,date.day)}
                    <button className="inlineArrowButton" onClick={() => setDate(changeDateByDays(date,1))}><i className="material-icons-outlined md-22">{'arrow_forward_ios'}</i></button>
                </h3>
                <div className="clearDiv" />
                {isCheckOut ?
                    <h2>{log.data().locationName}</h2>
                :
                    <select className="dateTimeButton" onChange={(e) => setSelectedLocation(e.target.value)} value={selectedLocation} >
                        <option key={'001'} value={'select'} >- Select -</option>
                        {!!locations ? locations.map(location => <option key={location.id} value={location.id} >{location.data().name}</option>) : null}
                    </select>
                }
                <div className="spacer clearDiv" />
                {showTime?
                    <div className="modal-back" >
                        <div className="modal-content">
                            <TimeKeeper time={time} onChange={(data) => {setTime({hour:data.hour,minute:data.minute, formatted:data.formatted12});}} onDoneClick={() => setShowTime(false)} switchToMinuteOnHourSelect={true} forceCoarseMinutes={true} coarseMinutes={5} closeOnMinuteSelect={false} />
                        </div>
                    </div>
                :
                    <button className="dateTimeButton" onClick={() => setShowTime(!showTime)}>{time.formatted}</button>
                }
                <div className="spacer clearDiv" />
                {isCheckOut ?
                    <button className="mainButtonContrast mainButtonContrastRed" onClick={() => {setLoading(true); logCheckOut(date,time,log.id,(result) => {setLoading(false); if(result === 'success'){dismiss()}});}} disabled={!validateCheckoutDate(date,time,log)}>Save</button>
                :
                    <button className="mainButtonContrast" onClick={() => {setLoading(true); logCheckIn(date,time,locationObject(locations,selectedLocation),(result) => {setLoading(false); if(result === 'success'){dismiss();window.location.assign('/');}});}} disabled={selectedLocation === 'select'}>Start</button>
                }
                <div className="spacer clearDiv" />
            </div>

            
        </div>
    )
}


let closedListener = null
function History(props){
    const clientData = props.clientData
    let locations = null
    if(!!clientData){
        locations = clientData.locations
    }
    const [showEdit, setShowEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [logHistory, setLogHistory] = useState('unloaded')
    if(logHistory === 'unloaded'){
        setLogHistory('loading')
        closedListener = listenForLogs(closedListener,false,setLogHistory,10)
    }
    return(
        <div>
            {showLoaderScreen(loading || logHistory === 'loading')}
            <h3>History</h3>
            <div className="spacer" />
            {logHistory !== 'loading' && logHistory !== 'unloaded' ? logHistory.map(log => logLineItem(log, setLoading)) : null}
            {logHistory.length === 0 ? <p>You have not yet created any logs.</p> : null}
        </div>
    )
}

function logLineItem(log, setLoading){
    const id = log.id
    const data = log.data()
    const open = data.open
    const location = data.locationName
    const checkInDateStr = getFormattedDateFromEpoch(data.inEpoch)
    const checkInTimeStr = getFormattedSimpleTime(data.inHour,data.inMin)
    

    return(
        <div key={id} className="lineItemLong">
            {open ? <button className="lineIcon" title="Open Log" ><i className="material-icons-outlined md-28 featureIcon">timer</i></button> : null}
            {/* <p className="lineItemText">{fullName+' in '+location+': '+getLogDateSummary(log)}</p> */}
            <p className="lineItemTextColumn"><span className="lineItemColumnHead">Location</span><br />{location}</p>
            <p className="lineItemTextColumn"><span className="lineItemColumnHead">Check in date</span><br />{checkInDateStr}</p>
            <p className="lineItemTextColumn"><span className="lineItemColumnHead">Check in time</span><br />{checkInTimeStr}</p>
            {!open ? 
                <div>
                    <p className="lineItemTextColumn"><span className="lineItemColumnHead">Check out date</span><br />{getFormattedDateFromEpoch(data.outEpoch)}</p>
                    <p className="lineItemTextColumn"><span className="lineItemColumnHead">Check out time</span><br />{getFormattedSimpleTime(data.outHour,data.outMin)}</p>
                </div>
            : null}
            <button className="lineButton" title="Delete Log" onClick={() => {if(window.confirm('Are you sure you want to delete this log?')){setLoading(true); deleteLog(log,(result)=>{setLoading(false);})}}} ><i className="material-icons-outlined md-28 featureIcon">delete_forever</i></button> 
        </div>
    )
}