import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import { onAuthStateChange, signOut } from './Firebase.js'
import {Login} from './Login.js'
import {Admin} from './Admin.js'
import {TraceApp} from './TraceApp.js'
import {showLoaderScreen} from './LoaderScreen.js'
import Footer from './Footer.js'
import {Privacy,Terms} from './Legal.js'
import {LandingPage, Onboard} from './Landing.js'


function App() {
  return (
    <div className="App">
      <div className="bodyHeight">
        <Router>
          <div>
            <header className="App-header">
              <div className="logoContainer"><a href="/"><img src="/ZippLog-Glyph.svg" alt="Workplace Contact Tracing" className="logo" /></a></div>
              <div className="clearDiv" />
            </header>
            <Switch>
              <Route path="/admin">
                <Admin />
              </Route>
              <Route path="/privacy">
                <Privacy />
              </Route>
              <Route path="/terms">
                <Terms/>
              </Route>
              <Route path="/onboard/:tier">
                <Onboard />
              </Route>
              <Route path="/signup/:clientID">
                <Login />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
      <Footer />
    </div>
  );
}

let authStateListener = null
function Home(){
  const [authChangeUser, onAuthChangeUser] = useState('unloaded');
  authStateListener = onAuthStateChange(onAuthChangeUser, authChangeUser, authStateListener)
  return(
    <div>
        {authChangeUser !== 'unloaded' ?
                authChangeUser === 'unauth' ? <LandingPage /> //<Login />
                :authChangeUser === 'admin'? <TraceApp authState={authChangeUser} />
                :authChangeUser === 'auth'? <TraceApp authState={authChangeUser} />
                :null
            :showLoaderScreen(true)
            }
    </div>
  )
}



export default App;
