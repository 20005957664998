import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link,useRouteMatch,useParams,Redirect} from "react-router-dom";
import { signOut, createCheckout, createNewClient, loadClient, kClientID, onAuthStateChange, getUsers, getAdmins, exportToCSV, listenExposures, listenToExposure, newExposure, setUserDisabled, setAdmin, adminListenToLogs, listenForAdmins, listenForLogs, logCheckIn, logCheckOut,getLogsForUser, saveLocation, getCurrentUser } from './Firebase.js'
import {Login,LoginModal} from './Login.js'
import {showLoaderScreen} from './LoaderScreen.js'
import QRCode from 'react-qr-code';
import {loadStripe} from '@stripe/stripe-js';

export function LandingPage(props){
    const [showLogin, setShowLogin] = useState(false);
    return(
        <div>
            {showLogin ? LoginModal(() => setShowLogin(false)) : null}
            <div className="landingContent">
                <h1><span style={{color:'#2E86DE'}}>Zipp</span><span style={{color:'#222F3E'}}>Log</span></h1>
                <button className="mainButtonContrast" onClick={() => setShowLogin(true)}><i className="material-icons-outlined md-22 mainButtonIcon">login</i> Sign in</button>
                <h2>Save time and money with easy workplace contact tracing.</h2>
                <p>ZippLog makes it easy for employees to check in and out of rooms for COVID contact tracing. Check in and out of work zones with a tap.</p>
            </div>
            <div className="spacer" />
            <div className="LandingImageDiv">
                <img src="./MarketingLanding01.png" alt="ZippLog COVID contact tracing" className="LandingImage" />
            </div>
            <div className="spacer" />
            <div className="landingContent">
                <h2>Get set up in under 5 minutes</h2>
                <Link to="/onboard/premium"><button className="mainButtonContrast">Get Started</button></Link>
            </div>
            <div className="spacer" />
            <div className="spacer" />
            <div className="landingContent">
                <h2>Easy Admin Control</h2>
                <p>Easily monitor logs and trace COVID exposures with the click of a button. Manage users and locations in an intuitive interface.</p>
            </div>
            <div className="LandingImageDiv">
                <img src="./DesktopDemo.png" alt="ZippLog COVID contact tracing" className="LandingImage" />
            </div>
            <div className="landingContent">
                <h2>Flexible Pricing</h2>
                <p>Pricing automatically scales as you do, so you always get the best price. Cancel anytime, no strings attached.</p>
            </div>
            <div className="FlexGrid priceDiv">
                <div className="GridItem priceItem topBorderGrey">
                    <h1>Small Business</h1>
                    <p>Up to 25 users</p>
                    <p><span className="priceText">$7</span><br />monthly per user</p>
                    <Link to="/onboard/smallbusiness"><button>Get Started</button></Link>
                </div>
                <div className="GridItem priceItem topBorderBlue">
                    <h1>Premium Business</h1>
                    <p>Up to 50 users</p>
                    <p><span className="priceText">$6</span><br />monthly per user</p>
                    <Link to="/onboard/premium"><button>Get Started</button></Link>
                </div>
                <div className="GridItem priceItem topBorderGrey">
                    <h1>Enterprise</h1>
                    <p>over 50 users</p>
                    <p><span className="priceText">$5</span><br />monthly per user</p>
                    <Link to="/onboard/enterprise"><button>Get Started</button></Link>
                </div>
            </div>
            <div className="spacer" />
            <div className="spacer" />
            <div className="landingContent">
                <h2>QR Code Quick Checkin</h2>
                <p>Generate QR tags for rooms to check in faster.</p>
            </div>
            <div className="LandingImageDiv">
                <img src="./QRDemo.png" alt="ZippLog COVID contact tracing" className="LandingImage" />
            </div>
            <div className="spacer" />
            <div className="landingContent">
                <h2>Got questions? Want to see more?</h2>
                <a href="mailto:hello@zipplog.com?subject=Requesting%20more%20info%20on%20ZippLog"><button className="mainButtonContrast">Send us an email</button></a>
            </div>
            <div className="spacer" />
            <Login isSignup={false} />
        </div>
    )
}

export function Onboard(props){
    let { tier } = useParams();
    return(
        <div>
            <Switch>
                <Route path="/onboard/:tier/success/:CHECKOUT_SESSION_ID">
                    <Redirect to="/admin" />
                </Route>
            </Switch>
            <NewClient tier={tier} />
        </div>
    )
}


function NewClient(props){
    const tier = props.tier
    const [clientName, setClientName] = useState('');
    const [email, setEmail] = useState('');
    const [fullName, setName] = useState('');
    const [pass, setPass] = useState('');
    const [conPass, setConPass] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false)

    return(
        <div id="login" className="authCard">
            {showLoaderScreen(loading)}
            <div>
                    <h2>{'Create your business account'}</h2>
                    <label className="label">Business Name
                        <input onChange={(e) => setClientName(e.target.value)} className="StripeElement stripeCustField" type="text" name="name" placeholder="Example Inc." value={clientName} />
                    </label>
                    <label className="label">Full Name
                        <input onChange={(e) => setName(e.target.value)} className="StripeElement stripeCustField" type="text" name="name" placeholder="John Doe" value={fullName} />
                    </label>
                    <label className="label">Email Address
                        <input onChange={(e) => setEmail(e.target.value)} className="StripeElement stripeCustField" type="text" name="email" placeholder="name@example.com" value={email} />
                    </label>
                    <label className="label">Password
                        <input onChange={(e) => setPass(e.target.value)} className="StripeElement stripeCustField" type="password" name="password" placeholder="Password" value={pass} />
                    </label>
                    <label className="label">Confirm Password
                        <input onChange={(e) => setConPass(e.target.value)} className="StripeElement stripeCustField" type="password" name="conpassword" placeholder="Confirm Password" value={conPass} />
                    </label>
                    {errorMsg && errorMsg.length > 0 ? <p className="redtext">{errorMsg}</p> : null}
                    {/* price_1IxOUXB0aOSCYdckmqfC5LTow */}
                    <button onClick={() => {submitClicked(email,pass,conPass,fullName,clientName,setErrorMsg,setLoading,tier,'price_1Iy6UGB0aOSCYdckb1rrHGqw');}} className="mainButtonContrast" disabled={false}>Proceed</button>
                    <br />
                    <br />
                    <div className="spacer" />
                    <div className="spacer" />
                    <nav>
                        By continuing you agree to the <Link to="/terms">Terms &amp; Conditions</Link> and  <Link to="/privacy">Privacy Policy</Link>
                    </nav>
                </div>
        </div>
    )
}

function submitClicked(email, pass, conPass,fullName, clientName, setErrorMsg,setLoading,tier,priceID){
    
    if(pass === conPass){
        if(!!clientName && clientName.length>3){
            if(!!fullName && fullName.length > 3){
                setLoading(true)
                loadStripe('pk_live_5eutWhwHGGJicIqWkJgjcntF00vO2ksJci')//('pk_test_iLCB12I9vSKCF7bvspNONNZe00S9Fw6bnJ')
                .then(stripe => {
                    console.log('Stripe Loaded')
                    createNewClient(email,pass,fullName,clientName,tier,window.location.href,priceID,setErrorMsg,setLoading)
                    .then(result => {
                        console.log('createCheckout called',result.data.sessionId, result)
                        // Call Stripe.js method to redirect to the new Checkout page
                        stripe
                        .redirectToCheckout({
                            sessionId: result.data.sessionId.id
                        })
                        .then(handleResult =>{console.log(handleResult)})
                        .catch(error => {
                            console.error('redirect',error)
                        })
                    })
                    .catch(error => {
                        console.error('createCheckout',error)
                    })
                })
                .catch(error => {
                    console.error('stripeLoad',error)
                })
            }else{
                setErrorMsg('Please enter your full name')
            }
        }else{
            setErrorMsg('Please enter your business name')
        }
    }else{
        setErrorMsg('The passwords you entered do not match')
    }
}