import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link,useRouteMatch,useParams} from "react-router-dom";
import { signOut, loadClient, kClientID, onAuthStateChange, getUsers, getAdmins,adminSnapLogs, exportToCSV, listenExposures, listenToExposure, newExposure, setUserDisabled, setAdmin, adminListenToLogs, listenForAdmins, listenForLogs, logCheckIn, logCheckOut,getLogsForUser, saveLocation, getCurrentUser } from './Firebase.js'
import {Login} from './Login.js'
import {showLoaderScreen} from './LoaderScreen.js'
import QRCode from 'react-qr-code';
import {FilterBar} from './FilterBar.js'
import {validateCheckoutDate, getFormattedDateFromEpoch, getFormattedSimpleTime, getDateTimeDaysAgo, locationObject, getCurrentDateTimeData, getDateTimeFromLog, getLogDateSummary, getFormattedDate, changeDateByDays} from './DateFunctions.js'

let authStateListener = null
export function Admin(props){
  const [authChangeUser, onAuthChangeUser] = useState('unloaded');
  authStateListener = onAuthStateChange(onAuthChangeUser, authChangeUser, authStateListener)
  return(
    <div>
        {authChangeUser !== 'unloaded' ?
                authChangeUser === 'unauth' ? <Login isSignup={false} />
                :authChangeUser === 'admin'? <AdminApp />
                :authChangeUser === 'auth'? <div>Oops! You are not authorized to view this page.<br /> <Link to="/">Go back to Home</Link></div>
                :null
            :showLoaderScreen(true)
            }
    </div>
  )
}

function AdminApp(props){
    let match = useRouteMatch();
    const [menu, setMenu] = useState(false);
    const [clientData, setClientData] = useState('unloaded');
    const [loading, setLoading] = useState(false);
    if(clientData === 'unloaded' && !loading && !!kClientID){
        setLoading(true)
        loadClient((data) => {setClientData(data); setLoading(false)})
    }
    const clientName = (clientData !== 'unloaded' ? clientData.clientDoc.clientName : null)
    return(
        <div>
            {showLoaderScreen(loading)}
            <h2>{clientName+' Admin'}</h2>
            {menu ?
                <div className="navMenu" onClick={()=> setMenu(!menu)}>
                    <button className="leftCornerMenu leftCornerMenuMain" onClick={()=> setMenu(!menu)}><i className="material-icons-outlined md-28 featureIcon">menu_open</i></button>
                    <nav className="navBtnBox">
                        <div className="navLightLine" />
                        <Link to={match.url}><button className="leftCornerMenu"><i className="material-icons-outlined md-28 featureIcon">dashboard</i> Dashboard</button></Link>
                        <br /><Link to={`${match.url}/locations`}><button className="leftCornerMenu"><i className="material-icons-outlined md-28 featureIcon">meeting_room</i> Locations</button></Link>
                        <br /><Link to={`${match.url}/users`}><button className="leftCornerMenu"><i className="material-icons-outlined md-28 featureIcon">people_outline</i> Users</button></Link>
                        <br /><Link to={`${match.url}/exposures`}><button className="leftCornerMenu"><i className="material-icons-outlined md-28 featureIcon">coronavirus</i> Exposure Tracing</button></Link>
                        <div className="navLightLine" />
                        <Link to="/"><button className="leftCornerMenu"><i className="material-icons-outlined md-28 featureIcon">home</i> Home</button></Link>
                        <br /><button className="leftCornerMenu" onClick={signOut}><i className="material-icons-outlined md-28 featureIcon">logout</i> Sign Out</button>
                    </nav>
                </div>
            :
                <button className="leftCornerMenu leftCornerMenuMain" onClick={()=> setMenu(!menu)}><i className="material-icons-outlined md-28 featureIcon">menu</i></button>
            }
            <Switch>
                <Route path={`${match.path}/locations`}>
                    <Locations clientData={clientData} />
                </Route>
                <Route path={`${match.path}/exposures`}>
                    <Exposures clientData={clientData} />
                </Route>
                <Route path={`${match.path}/exposure/:exposureID`}>
                    <Exposure clientData={clientData} />
                </Route>
                <Route path={`${match.path}/users`}>
                    <Users clientData={clientData} />
                </Route>
                <Route path={match.path}>
                    <Dashboard clientData={clientData} />
                </Route>
            </Switch>
            <div className="spacer" />
            <div className="spacer" />
            <div className="spacer" />
        </div>
      )
}


function Dashboard(props){
    const clientData = props.clientData
    let locations = null
    if(!!clientData){
        locations = clientData.locations
    }
    const [loading, setLoading] = useState(false);
    const [logHistory, setLogHistory] = useState([])
    const [logHistoryState, setLogHistoryState] = useState('unloaded')
    const [filters, setFilters] = useState({})
    if(logHistoryState === 'unloaded'){
        setLogHistoryState('loading')
        adminSnapLogs(setLogHistory,5,filters,logHistory,setLogHistoryState)
    }
    return(
        <div>
            {showLoaderScreen(loading || logHistory === 'loading')}
            <h3>Dashboard</h3>
            <div className="spacer" />
            {logHistory !== 'loading' && logHistory !== 'unloaded' ?
                <div>
                    <FilterBar filters={filters} setFilters={setFilters} allLocations={locations} reload={() => {setLogHistoryState('unloaded');}} export={()=>{setLoading(true);exportToCSV(resp => {setLoading(false);if(resp){window.open(resp, '_blank');}});}} />
                    {/* <div className="AlignExport"><button onClick={()=>{setLoading(true);exportToCSV(resp => {setLoading(false);if(resp){window.open(resp, '_blank');}});}} className="linkButton" title="Export to CSV"> <i className="material-icons-round md-28">file_download</i></button></div> */}
                    {logHistory.length > 0 ? null : <p>Nothing has been logged yet.</p>}
                    {logHistory.map(log => logLineItem(log))}
                    {logHistoryState === 'loaded' ? <button className="linkButton" onClick={() => setLogHistoryState('unloaded')}><i className="material-icons-outlined md-28 featureIcon">keyboard_arrow_down</i> Load more</button> : null}

                </div>
            : null}
        </div>
    )
}

function logLineItem(log){
    const id = log.id
    const data = log.data()
    const open = data.open
    const fullName = data.fullName
    const location = data.locationName
    const checkInDateStr = getFormattedDateFromEpoch(data.inEpoch)
    const checkInTimeStr = getFormattedSimpleTime(data.inHour,data.inMin)
    

    return(
        <div key={id} className="lineItemLong">
            {open ? <button className="lineIcon" title="Open Log" ><i className="material-icons-outlined md-28 featureIcon">timer</i></button> : null}
            {/* <p className="lineItemText">{fullName+' in '+location+': '+getLogDateSummary(log)}</p> */}
            <p className="lineItemTextColumn"><span className="lineItemColumnHead">Name</span><br />{fullName}</p>
            <p className="lineItemTextColumn"><span className="lineItemColumnHead">Location</span><br />{location}</p>
            <p className="lineItemTextColumn"><span className="lineItemColumnHead">Check in date</span><br />{checkInDateStr}</p>
            <p className="lineItemTextColumn"><span className="lineItemColumnHead">Check in time</span><br />{checkInTimeStr}</p>
            {!open ? 
                <div>
                    <p className="lineItemTextColumn"><span className="lineItemColumnHead">Check out date</span><br />{getFormattedDateFromEpoch(data.outEpoch)}</p>
                    <p className="lineItemTextColumn"><span className="lineItemColumnHead">Check out time</span><br />{getFormattedSimpleTime(data.outHour,data.outMin)}</p>
                </div>
            : null}
        </div>
    )
}

function Locations(props){
    const clientData = props.clientData
    let locations = null
    if(!!clientData){
        locations = clientData.locations
    }
    const [showEdit, setShowEdit] = useState(false);
    const [showQR, setShowQR] = useState(false);
    const [editLocation, setEditLocation] = useState(null)
    const [loading, setLoading] = useState(false);
    return(
        <div>
            {showLoaderScreen(loading)}
            <h3>Manage Locations</h3>
            <div className="spacer" />
            {locations && locations.length > 0 ? locations.map(location => lineItem(location,setLoading, setEditLocation, setShowEdit, setShowQR)) : <p>Add a location to get started:</p>}
            <div className="spacer" />
            <button className="linkButton" onClick={() => {setEditLocation(null); setShowEdit(true);}}><i className="material-icons-outlined md-28 featureIcon">add_circle_outline</i> New Location</button>
            {showEdit ? <EditLocation location={editLocation} dismiss={() => {setEditLocation(null); setShowEdit(false);}} /> : null}
            {showQR ? <QRCodeView location={editLocation} dismiss={() => {setEditLocation(null); setShowQR(false);}} /> : null}
        </div>
    )
}

function lineItem(location, setLoading, setEditLocation, setShowEdit, setShowQR){
    const id = location.id
    const title = location.data().name
    return(
      <div key={id} className="lineItemLong">
        <p className="lineItemTextColumn">{title}</p>
        <button className="lineButton" title="Remove" onClick={() => { if(window.confirm('Are you sure you want to remove this location?')){setLoading(true); saveLocation(id,title,false,(result) => {setLoading(false); if(result === 'success'){alert(title+' has been removed');window.location.reload();}});}}}><i className="material-icons-outlined md-28 featureIcon">delete</i></button>
        <button className="lineButton" title="Edit" onClick={() => {setEditLocation(location); setShowEdit(true);}}><i className="material-icons-outlined md-28 featureIcon">edit</i></button>
        <button className="lineButton" title="Generate QR Code" onClick={() => {setEditLocation(location); setShowQR(true);}}><i className="material-icons-outlined md-28 featureIcon">qr_code_2</i></button>
      </div>
    )
  }

function QRCodeView(props){
    const dismiss = props.dismiss
    const location = props.location
    return(
        <div className="modal-back-print" >
            <div className="modal-content">
                <button onClick={dismiss} className="closeButton no-print"> <i className="material-icons-round md-48">close</i></button>
                <div className="clearDiv" />
                <h1>{location.data().name}</h1>
                <button onClick={window.print} className="linkButton no-print"> <i className="material-icons-round md-28">print</i></button>
                <div className="spacer clearDiv" />
                <QRCode value={window.location.protocol+'//'+window.location.hostname+'/location/'+location.id} />
                <div className="spacer" />
                <div className="spacer" />
            </div>
        </div>
    )
}

  function EditLocation(props){
    const dismiss = props.dismiss
    const location = props.location
    const isNew = !(!!location && location.exists)
    let currentName = ''
    let currentActive = true
    if(!isNew){
        currentName = location.data().name
        currentActive = location.data().active
    }

    const [loading, setLoading] = useState(false)
    const [name, setName] = useState(currentName)
    //const [active, setActive] = useState(currentActive)



    return(
        <div className="modal-back" >
            <div className="modal-content">
                {showLoaderScreen(loading)}
                <button onClick={dismiss} className="closeButton"> <i className="material-icons-round md-48">close</i></button>
                <div className="clearDiv" />
                <h2>{isNew ? 'Create Location' : 'Edit Location'}</h2>
                <div className="clearDiv" />
                <label className="label">Location Name
                    <input onChange={(e) => setName(e.target.value)} className="StripeElement stripeCustField" type="text" name="locationName" placeholder="Location or zone name" value={name} />
                </label>
                <div className="spacer clearDiv" />
                {isNew ?
                    <button className="mainButtonContrast" onClick={() => {setLoading(true); saveLocation(null,name,true,(result) => {setLoading(false); if(result === 'success'){window.location.reload();}});}} disabled={!name || name.length < 3}>Create</button>
                :
                <button className="mainButtonContrast" onClick={() => {setLoading(true); saveLocation(location.id,name,true,(result) => {setLoading(false); if(result === 'success'){window.location.reload();}});}} disabled={!name || name.length < 3}>Save</button>
                }
                <div className="spacer clearDiv" />
            </div>

            
        </div>
    )
}

let adminListener = null
function Users(props){
    const clientData = props.clientData
    const [users, setUsers] = useState('unloaded');
    const [admins, setAdmins] = useState('unloaded');
    const [loading, setLoading] = useState(false);
    const [newExposure, setNewExposure] = useState(null);
    let signUpURL = window.location.protocol+'//'+window.location.hostname+'/signup/'+clientData.id
    if(users === 'unloaded' && !loading){
        setLoading(true)
        getUsers((array) => {setUsers(array); setLoading(false)})
    }
    if(admins === 'unloaded'){
        setAdmins('loading')
        adminListener = listenForAdmins(adminListener,setAdmins)
    }
    return(
        <div>
            {showLoaderScreen(loading)}
            {!!newExposure ? <CreateExposure user={newExposure} dismiss={() => setNewExposure(null)} /> : null}
            <h3>Manage Users</h3>
            <h3>Signup link: </h3><a href={signUpURL}>{signUpURL}</a>
            <p>Share this link to users to allow them to sign up in your organization.</p>
            <div className="spacer" />
            {users !== 'unloaded' ? users.map(user => userLineItem(user, admins, setLoading, setNewExposure)) : null}
        </div>
    )
}

function userLineItem(user, admins, setLoading, setNewExposure){
    const uid = user.id
    const email = user.data().email
    const fullName = user.data().fullName
    const isSelf = (uid === getCurrentUser().uid)
    const active = user.data().active
    let isAdmin = checkAdmin(admins, uid)
    let adminText = 'Are you sure you want to make this user and administrator?'
    if(isAdmin){
        adminText = 'Are you sure you want to remove this user as an administrator?'
    }
    //remove admin 'person_remove'
    return(
        <div key={uid} className="lineItemLong">
            <p className="lineItemTextColumn"><span className="lineItemColumnHead">Name</span><br />{fullName}</p>
            <p className="lineItemTextColumn"><span className="lineItemColumnHead">Email Address</span><br />{email}</p>
            <p className="lineItemTextColumn"><span className="lineItemColumnHead">Admin</span><br />{isAdmin ? 'Yes' : 'No'}</p>
            <p className="lineItemTextColumn"><span className="lineItemColumnHead">Enabled</span><br />{active ? 'Yes' : 'No'}</p>
            {/* <p className={'lineItemText' + (active ? '' : ' lineItemTextDisabled')}>{isAdmin ? 'Admin - ' : null}{fullName+' - '+email}{active ? null : ' - Disabled'}</p> */}
            {!isSelf ? <button className="lineButton" title={active ? 'Deactivate user' : 'Activate user'} onClick={() => {setLoading(true); setUserDisabled(uid,active,(result) => {setLoading(false); window.location.reload();})}} ><i className="material-icons-outlined md-28 featureIcon">{active ? 'toggle_on' : 'toggle_off'}</i></button> : null}
            {!isSelf && active ? <button className="lineButton" title={isAdmin ? 'Remove Administrator' : 'Make Administrator'} onClick={() => {if(window.confirm(adminText)){setLoading(true); setAdmin(!isAdmin,uid,(result) => {setLoading(false);});}}}><i className="material-icons-outlined md-28 featureIcon">{isAdmin ? 'person_remove' : 'admin_panel_settings'}</i></button>: null}
            <button className="lineButton" title="Start trace" onClick={() => setNewExposure(user)}><i className="material-icons-outlined md-28 featureIcon">coronavirus</i></button>
        </div>
    )
}

function checkAdmin(admins, uid){
    let isAdmin = false
    if(!!admins && admins !== 'unloaded' && admins !== 'loading' && admins.length > 0 && !!uid && uid.length > 0){
        admins.forEach(admin => {
            if(admin.data().uid === uid && admin.data().isAdmin){
                isAdmin = true
            }
        });
    }
    return isAdmin
}



let exposuresListener = null
function Exposures(props){
    const clientData = props.clientData
    const [exposures, setExposures] = useState('unloaded');
    const [loading, setLoading] = useState(false);
    if(exposures === 'unloaded' && exposures !== 'loading'){
        setExposures('loading')
        exposuresListener = listenExposures(exposuresListener,setExposures)
    }
    return(
        <div>
            {showLoaderScreen(loading || exposures === 'loading')}
            <h3>Exposure Tracing</h3>
            <p>View traced exposures here. To create a new item, click go to the Users page and click start a trace on a specific person.</p>
            <p>Click on an item to view the trace details</p>
            <div className="spacer" />
            {exposures !== 'unloaded' && exposures !== 'loading' ? exposures.map(exposure => exposureLineItem(exposure, setLoading)) : null}
            {exposures.length === 0 ? <p>You have not created any traces.</p> : null}
        </div>
    )
}

function exposureLineItem(exposure, setLoading){
    const exposureID = exposure.id
    const uid = exposure.data().uid
    const email = exposure.data().email
    const fullName = exposure.data().fullName
    const running = exposure.data().running
    const startEpoch = exposure.data().startDate.epoch
    const endEpoch = exposure.data().endDate.epoch
    return(
        <div key={exposureID} className="lineItemLong" onClick={() => window.location.assign('/admin/exposure/'+exposureID)}>
            {running ? <p className="lineItemTextColumn"><i className="material-icons-outlined md-28 featureIcon">hourglass_bottom</i></p> : null}
            <p className="lineItemTextColumn"><span className="lineItemColumnHead">Person</span><br />{fullName}</p>
            <p className="lineItemTextColumn"><span className="lineItemColumnHead">Email address</span><br />{email}</p>
            <p className="lineItemTextColumn"><span className="lineItemColumnHead">Trace start date</span><br />{getFormattedDateFromEpoch(startEpoch)}</p>
            <p className="lineItemTextColumn"><span className="lineItemColumnHead">Trace end date</span><br />{getFormattedDateFromEpoch(endEpoch)}</p>
            {/* <p className={'lineItemText'}>{running ? <i className="material-icons-outlined md-28 featureIcon">hourglass_bottom</i> : null}{fullName+' - '+email+'  -  '+getFormattedDateFromEpoch(startEpoch)+' - '+getFormattedDateFromEpoch(endEpoch)}</p> */}
            {!running ? <button className="lineButton" title="View Details" ><i className="material-icons-outlined md-28 featureIcon">info</i></button> : null}
        </div>
    )
}

function CreateExposure(props){
    const dismiss = props.dismiss
    const user = props.user
    const fullName = user.data().fullName
    const uid = user.id
    const email = user.data().email


    const currentDateData = getCurrentDateTimeData().date
    const startDateData = getDateTimeDaysAgo(14).date
    const [date, setDate] = useState(startDateData) //{year:2021, month:1, day:3}
    const [loading, setLoading] = useState(false)



    return(
        <div className="modal-back" >
            <div className="modal-content">
                {showLoaderScreen(loading)}
                <button onClick={dismiss} className="closeButton"> <i className="material-icons-round md-48">close</i></button>
                <div className="clearDiv" />
                <h2>Start new exposure trace</h2>
                <p>{fullName+' - '+email}</p>
                <h3>Start Date</h3>
                <h3>
                    <button className="inlineArrowButton" onClick={() => setDate(changeDateByDays(date,-1))}><i className="material-icons-outlined md-22">{'arrow_back_ios'}</i></button>
                        {getFormattedDate(date.year,date.month,date.day)}
                    <button className="inlineArrowButton" onClick={() => setDate(changeDateByDays(date,1))}><i className="material-icons-outlined md-22">{'arrow_forward_ios'}</i></button>
                </h3>
                <div className="spacer clearDiv" />
                {/* (result) => {setLoading(false); if(result === 'success'){dismiss();window.location.assign('/');}} */}
                <button className="mainButtonContrast" onClick={() => {setLoading(true); newExposure(uid,fullName,email,date,currentDateData,(result) => {setLoading(false); if(result === 'success'){window.location.assign('/admin/exposures');}})}} disabled={false}>Create</button>
                <div className="spacer clearDiv" />
            </div>

            
        </div>
    )
}

let exposureListener = null
function Exposure(props){
    const clientData = props.clientData
    let { exposureID } = useParams();
    let email = ''
    let fullName = ''
    let running = false
    let start = ''
    let end = ''
    let l1Exposures = null
    let l2Exposures = null

    const [exposure, setExposure] = useState('unloaded');
    const [loading, setLoading] = useState(false);
    if(exposure === 'unloaded'){
        setExposure('loading')
        exposureListener = listenToExposure(exposureListener,exposureID,setExposure)
    }else if(!!exposure && exposure !== 'loading'){
        email = exposure.data().email
        fullName = exposure.data().fullName
        running = exposure.data().running
        start = getFormattedDateFromEpoch(exposure.data().startDate.epoch)
        end = getFormattedDateFromEpoch(exposure.data().endDate.epoch)
        l1Exposures = exposure.data().L1Exposures
        l2Exposures = exposure.data().L2Exposures
    }
    return(
        <div>
            {showLoaderScreen(loading || exposure === 'loading')}
            <h3>Exposure Trace</h3>
            <div className="spacer" />
            {exposure !== 'unloaded' && exposure !== 'loading' && !!exposure ? 
                <div>
                    <p>{fullName+' - '+email}</p>
                    <p>{start+' - '+end}</p>
                    <div className="spacer" />
                    <h3>Level 1 Exposures</h3>
                    <p>People directly exposed to the infected person.</p>
                    {!!l1Exposures && l1Exposures.length > 0 ? l1Exposures.map(l1Exposure => exposuredPersonLineItem(l1Exposure, setLoading)) : <p>No Exposures</p>}
                    <div className="spacer" />
                    <h3>Level 2 Exposures</h3>
                    <p>People exposed to other exposed people, but not directly to the infected person.</p>
                    {!!l2Exposures && l2Exposures.length > 0 ? l2Exposures.map(l2Exposure => exposuredPersonLineItem(l2Exposure, setLoading)) : <p>No Exposures</p>}
                </div>
            : null}
        </div>
    )
}

function exposuredPersonLineItem(exposure, setLoading){
    const uid = exposure.uid
    const email = exposure.email
    const fullName = exposure.fullName
    const dateExposed = getFormattedDateFromEpoch(exposure.earliestEpoch)
    return(
        <div key={uid} className="lineItemLong">
            <p className="lineItemTextColumn"><span className="lineItemColumnHead">Person</span><br />{fullName}</p>
            <p className="lineItemTextColumn"><span className="lineItemColumnHead">Email address</span><br />{email}</p>
            <p className="lineItemTextColumn"><span className="lineItemColumnHead">Exposed</span><br />{dateExposed}</p>
            {/* {!running ? <button className="lineButton" title="View Details" ><i className="material-icons-outlined md-28 featureIcon">info</i></button> : null} */}
        </div>
    )
}