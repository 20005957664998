import React, {useState} from 'react';
import { signIn, forgotPassword,createAccount,onAuthStateChange, signOut, validateClientID } from './Firebase.js'
import { BrowserRouter as Router, Switch, Route, Link,useRouteMatch,useParams} from "react-router-dom";
import {showLoaderScreen} from './LoaderScreen.js'

let authStateListener = null
export function AuthUI(props){
    const [showSignIn, setShowSignIn] = useState(false)
    const [authChangeUser, onAuthChangeUser] = useState('unloaded');
    authStateListener = onAuthStateChange(onAuthChangeUser, authChangeUser, authStateListener)
    return(
        <div className="zeroPadMar">
            {showSignIn ? LoginModal(()=>setShowSignIn(false)) : null}
            {authChangeUser !== 'unloaded' ?
                    authChangeUser === 'unauth' ? <button onClick={() => setShowSignIn(true)} className="signInButton linkButton">Sign in</button>
                    :authChangeUser === 'admin'? <button onClick={() => signOut()} className="signInButton linkButton">Sign out</button>
                    :authChangeUser === 'auth'? <button onClick={() => signOut()} className="signInButton linkButton">Sign out</button>
                    :null
                :<button className="signInButton linkButton" disabled={true}>Sign in</button>
                }
        </div>
    )
}

export function LoginModal(dismiss){
    return(
        <div className="modal-back" >
            <div className="modal-content">
            <button onClick={dismiss} className="closeButton"> <i className="material-icons-round md-48">close</i></button>
                {<Login dismiss={dismiss} />}
            </div>
        </div>
    )
}

export function Login(props){
    let match = useRouteMatch();
    return(
        <div>
            <Switch>
                <Route path="/signup/:clientID">
                    <LoginUI isSignup={true} />
                </Route>
                <Route path={match.path}>
                    <LoginUI isSignup={false} />
                </Route>
            </Switch>
        </div>
    )
}

function LoginUI(props){
    let { clientID } = useParams();
    const propIsSignup = !!props.isSignup
    const dismiss = props.dismiss
    const [email, setEmail] = useState('');
    const [fullName, setName] = useState('');
    const [pass, setPass] = useState('');
    const [conPass, setConPass] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    //const [isSignup, setIsSignup] = useState(propIsSignup)
    const isSignup = propIsSignup;
    const [clientIDValid, setClientIDValid] = useState('unloaded')

    if(isSignup && !!clientID && clientIDValid === 'unloaded'){
        validateClientID(clientID, setClientIDValid)
    }

    return(
        <div id="login" className="authCard">
            {showLoaderScreen(isSignup && clientIDValid === 'unloaded')}

            {!isSignup || clientIDValid === 'valid' ? 
                <div>
                    <h2>{isSignup ? 'Create your account' : 'Sign In'}</h2>
                    {isSignup ?
                    <label className="label">Full Name
                        <input onChange={(e) => setName(e.target.value)} className="StripeElement stripeCustField" type="text" name="name" placeholder="John Doe" value={fullName} />
                    </label>
                    :null}
                    <label className="label">Email Address
                        <input onChange={(e) => setEmail(e.target.value)} className="StripeElement stripeCustField" type="text" name="email" placeholder="name@example.com" value={email} />
                    </label>
                    <label className="label">Password
                        <input onChange={(e) => setPass(e.target.value)} className="StripeElement stripeCustField" type="password" name="password" placeholder="Password" value={pass} />
                    </label>
                    {isSignup ?
                    <label className="label">Confirm Password
                        <input onChange={(e) => setConPass(e.target.value)} className="StripeElement stripeCustField" type="password" name="conpassword" placeholder="Confirm Password" value={conPass} />
                    </label>
                    :null}
                    {errorMsg && errorMsg.length > 0 ? <p className="redtext">{errorMsg}</p> : null}
                    <button onClick={() => {submitClicked(email,pass,conPass,fullName,isSignup,clientID,setErrorMsg); if(dismiss) dismiss();}} className="mainButtonContrast" disabled={false}>{isSignup ? 'Sign Up' : 'Sign In'}</button>
                    <br />
                    <br />
                    {/* <button className="linkButton" onClick={() => setIsSignup(!isSignup)}>{isSignup ? 'Sign In' : 'Sign Up'}</button> <br /><br /> */}
                    <button className="linkButton" onClick={() => forgotPassword(email, setErrorMsg)}>Forgot Password</button>
                    <br />
                    <div className="spacer" />
                    <div className="spacer" />
                    <nav>
                        By continuing you agree to the <Link to="/terms">Terms &amp; Conditions</Link> and  <Link to="/privacy">Privacy Policy</Link>
                    </nav>
                </div>
            :
                clientIDValid === 'unloaded' ? null : <h2>This is an ivalid signup link</h2>
            }
        </div>
    )
}

//Limit this to only sign up for link with clientID!!!!!!!!!!!!!!!!!!!!!!
function submitClicked(email, pass, conPass,fullName,isSignup, clientID, setErrorMsg){
    if(isSignup){
        if(pass === conPass){
            if(!!clientID){
                if(!!fullName && fullName.length > 3){
                    createAccount(email,pass,conPass,fullName,clientID,(res) => {if(res === ''){window.location.assign('/');}else{setErrorMsg(res);}})
                }else{
                    setErrorMsg('Please enter your full name')
                }
            }else{
                setErrorMsg('This Sign Up link is invalid.')
            }
        }else{
            setErrorMsg('The passwords you entered do not match')
        }
    }else{
        signIn(email, pass, setErrorMsg)
    }
}

export default Login
