import {kAppVersion} from './Firebase.js'

function Footer(){
    return(
        <footer>
          <img src="/ZippLog-Glyph.svg" alt="Workplace Contact Tracing" className="footerBrand" />
          <div className="footerInfo">
            <h2 className="footer"><a href="mailto:hello@zipplog.com">Send us an email</a></h2>

            
          </div>
          <div className="lightLine" />
          <p className="footer textRight">{kAppVersion} | <a href="/terms">Terms &amp; Conditions</a> | <a href="/privacy">Privacy Policy</a> | © ZippLog 2021 | <span className="powered">Powered by <a className="powered" href="https://foxbit.ca">Foxbit</a></span></p>
        </footer>
    )
}

export default Footer