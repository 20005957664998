export function validateCheckoutDate(date,time,log){
    const logData = log.data()
    const inYear = logData.inYear
    const inMonth = logData.inMonth
    const inDay = logData.inDay
    const inHour = logData.inHour
    const inMin = logData.inMin

    let valid = false
    if(date.year > inYear){
        valid = true
    }else if(date.year === inYear && date.month > inMonth){
        valid = true
    }else if(date.year === inYear && date.month === inMonth && date.day > inDay){
        valid = true
    }else if(date.year === inYear && date.month === inMonth && date.day === inDay && time.hour > inHour){
        valid = true
    }else if(date.year === inYear && date.month === inMonth && date.day === inDay && time.hour === inHour && time.minute >= inMin){
        valid = true
    }else{
        valid = false
    }
    return valid
}


export function locationObject(locations, id){
    let locObject = null
    locations.forEach(location => {
        if(location.id === id){
            locObject = location
        }
    });
    return locObject
}

export function getCurrentDateTimeData(){
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth()+1 //getMonth is 0 indexed, ie jan is 0
    const day = currentDate.getDate()
    const hour = currentDate.getHours()
    const minute = currentDate.getMinutes()

    const formattedTime = getFormattedSimpleTime(hour,minute)

    const timeData = {hour: hour, minute: minute, formatted: formattedTime}
    const dateData = {year:year, month:month, day:day}

    return {time:timeData,date:dateData}
}

export function getDateTimeDaysAgo(daysAgo){
    const currentDate = new Date(Date.now() - daysAgo * 24 * 60 * 60 * 1000)
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth()+1 //getMonth is 0 indexed, ie jan is 0
    const day = currentDate.getDate()
    const hour = currentDate.getHours()
    const minute = currentDate.getMinutes()

    const formattedTime = getFormattedSimpleTime(hour,minute)

    const timeData = {hour: hour, minute: minute, formatted: formattedTime}
    const dateData = {year:year, month:month, day:day}

    return {time:timeData,date:dateData}
}

export function getDateTimeFromLog(log, isOut){
    const logData = log.data()
    let formattedDate = null
    let formattedTime = null
    if(isOut){
        formattedDate = getFormattedDate(logData.outYear,logData.outMonth,logData.outDay)
        formattedTime = getFormattedSimpleTime(logData.outHour,logData.outMin)
    }else{
        formattedDate = getFormattedDate(logData.inYear,logData.inMonth,logData.inDay)
        formattedTime = getFormattedSimpleTime(logData.inHour,logData.inMin)
    }

    return formattedDate+' at '+formattedTime
}

export function getEpochFor(date, time){
    const dateMonth = date.month-1 ////getMonth is 0 indexed, ie jan is 0
    const dateObject = new Date()

    dateObject.setFullYear(date.year)
    dateObject.setMonth(dateMonth)
    dateObject.setDate(date.day)
    dateObject.setHours(time.hour)
    dateObject.setMinutes(time.minute)

    return dateObject.getTime()
}

export function addEpoch(date){
    const dateMonth = date.month-1 ////getMonth is 0 indexed, ie jan is 0
    const dateObject = new Date()

    dateObject.setFullYear(date.year)
    dateObject.setMonth(dateMonth)
    dateObject.setDate(date.day)

    date['epoch'] = dateObject.getTime()

    return date
}

export function getLogDateSummary(log){
    const logData = log.data()
    const open = logData.open

    const formattedInDate = getFormattedDate(logData.inYear,logData.inMonth,logData.inDay)
    const formattedInTime = getFormattedSimpleTime(logData.inHour,logData.inMin)
    let summary = formattedInDate+' at '+formattedInTime
    let formattedOutDate = ''
    let formattedOutTime = ''
    if(!open){
        summary = summary + ' - '
        formattedOutTime = getFormattedSimpleTime(logData.outHour,logData.outMin)
        if(!(logData.inYear === logData.outYear && logData.inMonth === logData.outMonth && logData.inDay === logData.outDay)){
            formattedOutDate = getFormattedDate(logData.outYear,logData.outMonth,logData.outDay)
            summary = summary + formattedOutDate +' at '
        }
        summary = summary + formattedOutTime
    }
    return summary
}

export function getFormattedDate(year,month,day){
    const dateMonth = month-1 ////getMonth is 0 indexed, ie jan is 0
    const date = new Date()

    date.setFullYear(year)
    date.setMonth(dateMonth)
    date.setDate(day)

    const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options)
}

export function getFormattedDateFromEpoch(epoch){
    const date = new Date(epoch)
    const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options)
}

export function getFormattedSimpleTime(hour, minute){
    let ampm = 'am'
    if(hour >= 12){
        ampm = 'pm'
        hour = hour - 12
    }
    if(hour === 0){
        hour = 12
    }

    let minutebuffer = ''
    if(minute < 10){
        minutebuffer = '0'
    }

    return hour+':'+minutebuffer+minute+' '+ampm
}

export function changeDateByDays(date,delta){
    let year = date.year
    let month = date.month
    let day = date.day

    day = day+delta

    if(day < 1){
        month = month-1
        if(month < 1){
            month = 12
            year = year-1
        }
        day = (getDaysForMonth(month)-day)
    }
    if(day > getDaysForMonth(month)){
        day = (day - getDaysForMonth(month))
        month = month+1
        if(month > 12){
            month = 1
            year = year + 1
        }
    } 
    return {year:year, month:month, day:day};
}

export function getDaysForMonth(month,year){
    if(month === 1 || month === 3 || month === 5 || month === 7 || month === 8 || month === 10 || month === 12){
        return 31
    }
    if(month === 4 || month === 6 || month === 9 || month === 11){
        return 30
    }
    if(month === 2){
        if(leapYear(year)){
            return 29
        }else{
            return 28
        } 
    }
}

export function leapYear(year)
{
  return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
}