import React, { useState } from 'react';

export function FilterBar(props){
    const filters = props.filters
    const setFilters = props.setFilters
    const reload = props.reload
    const allLocations = props.allLocations
    const exportCSV = props.export

    const [open, setOpen] = useState((filters && Object.keys(filters).length > 0));
    const [locations, setLocations] = useState(filters.location || []);
    const [locationsOpen, setLocationsOpen] = useState((locations && locations.length > 0));
    const [people, setPeople] = useState(filters.people || []);
    const [peopleOpen, setPeopleOpen] = useState((people && people.length > 0));


    return (
      <div className="filterBar">
        {!!exportCSV ? <button className={'bubbleButton'} onClick={() => exportCSV()}><i className="material-icons-outlined md-28 featureIcon">file_download</i> Export to CSV</button> : null}
        <button className={open ? 'bubbleButton bubbleSelected' : 'bubbleButton'} onClick={() => setOpen(!open)}><i className="material-icons-outlined md-28 featureIcon">filter_list</i> Filters</button>
        {open ?
          <div>
            <button className={locationsOpen ? 'bubbleButton bubbleSelected' : 'bubbleButton'} onClick={() => setLocationsOpen(!locationsOpen)}><i className="material-icons-outlined md-28 featureIcon">meeting_room</i> Location</button>
            {locationsOpen  ? <div><div className="clearDiv" />{FilterSelection(locations,(newLocationsArray => {setLocations(newLocationsArray);checkAddFilters('location',newLocationsArray,filters,setFilters,reload)}),getLocationsList(allLocations))}<div className="clearDiv" /></div> : null}
            {/* <button className={peopleOpen ? 'bubbleButton bubbleSelected' : 'bubbleButton'} onClick={() => setPeopleOpen(!peopleOpen)}><i className="material-icons-outlined md-28 featureIcon">person</i> Person</button> */}
            {/* {peopleOpen  ? <div><div className="clearDiv" />{FilterSelection(people,(newPeopleArray => {setPeople(newPeopleArray);checkAddFilters('users',newPeopleArray,filters,setFilters,reload)}),allUsers)}<div className="clearDiv" /></div> : null} */}
          </div>
        : null}
        <div className="clearDiv" />
      </div>
    );
  }

  function getLocationsList(locationsDocs){
      const list = []
      if(!!locationsDocs){
        locationsDocs.forEach(doc =>{
            list.push(doc.data().name)
        })
      }
      return list;
  }

  export function FilterSelection(selected, setSelected, items){
    return (
        <div>
            {items.map(item => <div key={item} onClick={() => featureClicked(item,selected,setSelected)} className={featureIsSelected(item,selected) ? 'optionLine optionLineSelected' : 'optionLine'}>{item}</div>)}
        </div>
    );
}

export function featureClicked(feature,selected,setSelected){
    const wasSelected = featureIsSelected(feature,selected)
    if(wasSelected){
        let index = selected.indexOf(feature)
        if(index >= 0 && index < selected.length){
            selected.splice(index, 1)
        }
    }else{
        selected.push(feature)
    }
    setSelected([...selected])
    console.log(selected)
    
}

export function featureIsSelected(feature, selected){
    //feature is string key, selected is array of selected (can be empty)
    let isSelected = false;
    if(feature && feature.length > 0 && selected && selected.length > 0){
        selected.forEach(item => {
            if(feature === item){
                isSelected = true
            }
        })
    }
    return isSelected
}



//   export function CitySearchBar(props){
//     const [search, setSearch] = useState('');
//       return(
//           <div>
//               <input onChange={(e) => setSearch(e.target.value)} className="StripeElement stripeCustField" type="text" name="City" placeholder="Where are you looking?" disabled={false} value={search} />
//           </div>
//       )
//   }

  


  
  function checkAddFilters(key,value,filters,setFilters,reload){
    if(!!value){
        if(Array.isArray(value) && value.length <= 0){
            delete filters[key]
        }else{
            filters[key] = value
        }
    }else{
        delete filters[key]
    }
    const newFilters = {...filters}
    console.log(newFilters)
    setFilters(newFilters)
    reload()
  }

  function setURLParams(param){
    //const url = new URL(window.location.hostname);
    
    window.history.replaceState({}, document.title, '?'+param);
  }

  export function convertStringValue(value,key){
      if(value === 'true'){
          return true
      }
      if(value === 'false'){
          return false
      }
      if(key && key.length >0 && (key === 'features' || key === 'city')){
        console.log(value.split(','))
          return value.split(',')
      }
      if(!Number.isNaN(Number(value))){
          return Number(value)
      }
      return value
  }

  export function opForKey(key){
    if(key === 'features'){
        return 'array-contains-any'
    }
    if(key === 'city'){
        return 'in'
    }
    if(key === 'highPrice'){
        return '<='
    }
    if(key === 'lowPrice'){
        return '>='
    }
    if(key === 'highSqft'){
        return '<='
    }
    if(key === 'lowSqft'){
        return '>='
    }
    return '=='
  }

  export function queryKeyForKey(key){
    if(key === 'highPrice'){
        return 'price'
    }
    if(key === 'lowPrice'){
        return 'price'
    }
    if(key === 'highSqft'){
        return 'sqft'
    }
    if(key === 'lowSqft'){
        return 'sqft'
    }
        return key
  }

  export function searchCities(search){
      const cities = getCitiesArray()
      const srch = search.toLowerCase().trim()
      const results = []
      cities.forEach(city => {
          if(city.includes(srch)){
              results.push(city)
          }
      })
      return (results)
  }

  export function getCitiesArray(){
      return [
          'Altona',
          'Carman',
          'Morden',
          'Manitou',
          'Miami',
          'Plum Coulee',
          'Roland',
          'RM of Stanley',
          'RM of Rhineland',
          'Winkler',   
      ]
  }